import React, { useCallback, useEffect, useState } from 'react';
import { useModalDeCorrecoes } from '../../ModalDeCorrecoesContext';
import { Container } from './styles';
import { InputAsyncSelect } from '~/components/NovosInputs';
import { useForm } from 'react-hook-form';
import { useValidadorXML } from '~/pages/ValidadorXML/ValidadorContext';
import api from '~/services/api';
import { moneyFormat } from '~/utils/functions';

/**
 * @param AlterarNCM Aqui é efetuada a substituição do NCM por outro existente no ERP
 */

export const AlterarNCM: React.FC = () => {
  const {
    dadosItemACorrigir,
    novoNCM,
    setNovoNCM,
    loading,
    modalTab,
    showData,
    setShowData,
  } = useModalDeCorrecoes();

  const { dadosDaCapa, showModalDeCorrecoes } = useValidadorXML();

  const [ncmData, setNcmData] = useState<{
    cod_ncm: number;
    des_ncm: string;
    num_ncm: string;
  } | null>(null);
  const [tributacaoData, setTributaoData] = useState<any>(null);

  const { register, control, clearErrors, formState } = useForm({
    reValidateMode: 'onBlur',
  });

  const handleNCMSelected = useCallback(
    (selected: any) => {
      setNovoNCM(selected);
      setShowData('NCM');
    },
    [setNovoNCM, setShowData],
  );

  const TipoNaoPisCofinsDesc = useCallback(() => {
    switch (novoNCM.tipo_nao_pis_cofins) {
      case -1:
        return 'Incide PIS/COFINS';
      case 0:
        return 'Aliq. Zero';
      case 1:
        return 'Monofásico';
      case 2:
        return 'Substituição';
      case 3:
        return 'Imune';
      case 4:
        return 'Suspensão';

      default:
        return '';
    }
  }, [novoNCM]);

  const getNcm = useCallback(async (cod_ncm: number, num_ncm: string) => {
    const { data } = await api.get(
      `/validador-xmls/busca-ncm/${cod_ncm}/${num_ncm}`,
    );
    setNcmData(data.ncm);
  }, []);

  useEffect(() => {
    if (dadosItemACorrigir && showModalDeCorrecoes) {
      const { cod_ncm, num_ncm, NCM } = dadosItemACorrigir;
      const numNcm = num_ncm || NCM;

      if (typeof numNcm !== 'undefined' && typeof cod_ncm !== 'undefined') {
        getNcm(cod_ncm, numNcm);
      }
    }
  }, [dadosItemACorrigir, showModalDeCorrecoes, getNcm]);

  const labelNcm =
    dadosItemACorrigir && ncmData
      ? `${ncmData.cod_ncm} - ${ncmData.num_ncm} - ${ncmData.des_ncm}`
      : '';

  const getTributacao = useCallback(async (cod_tributacao: number) => {
    const { data } = await api.get(
      `/validador-xmls/busca-tributacao/${cod_tributacao}`,
    );

    setTributaoData(data.tributacao);
  }, []);

  useEffect(() => {
    if (!dadosItemACorrigir) return;

    const { cod_trib_ent_ncm } = dadosItemACorrigir;

    if (typeof cod_trib_ent_ncm !== 'undefined')
      getTributacao(cod_trib_ent_ncm);
  }, [dadosItemACorrigir, getTributacao]);

  return (
    <Container className="container">
      <div className="row">
        <div className="col-md-12">
          <InputAsyncSelect
            label="Filtrar NCM"
            maxLength={50}
            placeholder="Digite o número ou descrição para consulta..."
            name="num_ncm"
            register={register}
            isError={!!formState.errors.num_ncm}
            control={control}
            disabled={loading}
            changeSelected={(selected: any) => {
              clearErrors('num_ncm');
              handleNCMSelected(selected);
            }}
            api={{
              route: '/busca-ncm',
              method: 'get',
              bodyParams: {
                des_uf_loja: dadosDaCapa.des_uf,
                cod_loja: dadosDaCapa.cod_loja,
                cod_tipo_item_prod: dadosItemACorrigir.tipo_especie,
                modalTab: modalTab === 'alterarNCM' ? true : undefined,
              },
              fields: ['cod_ncm', 'num_ncm', 'des_ncm', 'num_cest'],
              fildDescriptionForValue: 'cod_ncm',
              dependsOf: ['modalTab'],
            }}
          />
        </div>
        <div className="col-12">
          <table className="table">
            <thead>
              <tr>
                <th>NCM XML</th>
                <th>CEST XML</th>
                <th>NCM ERP</th>
                <th>CEST ERP</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{dadosItemACorrigir.NCM}</td>
                <td>{dadosItemACorrigir.CEST}</td>
                <td>{labelNcm}</td>
                <td>{dadosItemACorrigir.num_cest}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="col-12">
          <table className="table">
            <thead>
              <tr>
                {dadosItemACorrigir && <th>Tributação XML</th>}
                {tributacaoData && <th>Tributação ERP</th>}
              </tr>
            </thead>
            <tbody>
              <tr>
                {dadosItemACorrigir && (
                  <td>{`CST ${
                    dadosItemACorrigir.xmlCST?.length < 3
                      ? `0${dadosItemACorrigir.xmlCST}`
                      : dadosItemACorrigir.xmlCST
                  } - ICMS ${dadosItemACorrigir.pICMS} - Red. ${
                    dadosItemACorrigir.pRedBC
                  }`}</td>
                )}
                {tributacaoData && (
                  <td>{`${
                    tributacaoData.cod_tributacao
                  } - RED.${tributacaoData.val_reducao
                    ?.toString()
                    .replace('.', ',')}% | CST ${
                    tributacaoData.cod_situacao_tributaria
                  } - ${tributacaoData.des_tributacao}`}</td>
                )}
              </tr>
            </tbody>
          </table>
        </div>
        {showData === 'NCM' && (
          <div className="col-sm-12 mt-4">
            <table className="table">
              <thead>
                <tr>
                  <th>Cód. NCM</th>
                  <th>Num. NCM</th>
                  <th>Descrição</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{novoNCM.cod_ncm}</td>
                  <td>{novoNCM.num_ncm}</td>
                  <td>{novoNCM.des_ncm}</td>
                </tr>
              </tbody>
            </table>
            <table className="table">
              <thead>
                <tr>
                  <th>Cód. Trib. Entrada</th>
                  <th>Des. Trib. Entrada</th>
                  <th>Cód. Tributação</th>
                  <th>Des. Tributação</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{novoNCM.cod_trib_entrada}</td>
                  <td>{novoNCM.des_trib_ent}</td>
                  <td>{novoNCM.cod_tributacao}</td>
                  <td>{novoNCM.des_trib_saida}</td>
                </tr>
              </tbody>
            </table>
            <table className="table">
              <thead>
                <tr>
                  <th>Per. IVA</th>
                  <th>Não Incide PIS/COFINS</th>
                  <th>Tipo Não Incide PIS/COFINS</th>
                  <th>CEST</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{moneyFormat(String(novoNCM.per_iva || '0'))}</td>
                  <td>{novoNCM.flg_nao_pis_cofins ? 'SIM' : 'NÃO'}</td>
                  <td>{TipoNaoPisCofinsDesc()}</td>
                  <td>{novoNCM.num_cest}</td>
                </tr>
              </tbody>
            </table>
          </div>
        )}
      </div>
    </Container>
  );
};
